<template>
  <div class="vip">
    <van-divider
      v-if="vipStatus"
      :style="{ color: '#999', borderColor: '#999', padding: '0 16px' }"
      >ەرەكشە مۇشە ۋاقىتىڭىز اياقتالۋعا</van-divider
    >

    <count-down :endTime="endTime" />

    <!-- <div style="margin: 0 20px" v-if="!vipStatus">
      <van-button round type="danger" block to="oldvip">بۇرىن مۇشەلىك اشتىرعاندارعا</van-button>
    </div> -->

    <div class="vip-desc" v-if="!vipStatus">
      <van-divider
        :style="{ color: '#999', borderColor: '#999', padding: '0 16px' }"
        >ەسكەرتۋ</van-divider
      >

      VIP مۇشەلىگىن ساتىپ الساڭىز بەلگىلەنگەن ۋاقىت ىشىندە باعدارلاماداعى VIP
      مازمۇنداردى تەگىن، جارناماسىز كورە الاسىز. مازمۇن كورۋ بارىسىندا
      ماسەلەلەرگە جولىقساڭىز باعدارلاما جاۋاپتىسمەن قابارلاسىڭىز! (VIP بەلگىسى
      جوق مازمۇندار جەكە ساتىپ الىنادى)
    </div>
    <van-notice-bar
      class="rtl notice"
      color="#ed1c24"
      background="#ecf9ff"
      right-icon="info-o"
      wrapable
      >3 ايدان ارتىق مۇشە بولعاندارعا سيلىق بەرىلەدى. مۇشە بولعانسىن تەحنيكالىق
      جاۋاپتىعا قابارلاسىپ سيلىقتى الىڭىز.<van-button type="danger" to="/help"
        >قابارلاسۋ</van-button
      >
    </van-notice-bar>
    <div class="memberlist" v-for="item in members" :key="item.id">
      <div>
        <h4>{{ item.title }}</h4>
        <p>ۋاقىتى: {{ item.limited_time }} كۇن، سوماسى {{ item.price }} يۋان</p>
      </div>
      <van-button
        @click="buyVip(item)"
        round
        type="danger"
        :disabled="vipStatus"
        >ساتىپ الۋ</van-button
      >
    </div>
    <div class="vip-tips">
      ەرەكشە مۇشە بولىنعان اقى قايتارىلمايدى. جوعارىداعى ەسكەرتۋدى جاقىسلاپ
      وقىڭىز.
    </div>
  </div>
</template>

<script>
import wx from 'weixin-js-sdk'
import { wechatRedirect } from '@/api/user'
import {
  getMembers,
  vipStatus,
  createVipMember,
  orderPayStatus
} from '@/api/member'
import { addDate, today } from '../../utils/membertime.js'
import { setItem } from '@/utils/storage'
import CountDown from '@/components/CountDown'

export default {
  data () {
    return {
      members: [],
      vip_status: true,
      payParams: {},
      limitedDay: '',
      endTime: '',
      vipStatus: false
    }
  },
  mounted () {
    document.title = 'ەرەكشە مۇشە رايونى'
    this.getMember()
    this.getVipStatus()
  },
  components: {
    CountDown
  },
  methods: {
    async getVipStatus () {
      const { data: res } = await vipStatus()
      setItem('vipStatus', res.status)
      const limitedTime = res.limited_time.toString().replace(/-/g, '/')
      const endTime = new Date(limitedTime).getTime() / 1000 + ' '
      this.endTime = endTime
      this.vipStatus = res.status
    },
    async getMember () {
      try {
        const { data } = await getMembers()
        this.members = data.data
      } catch (error) {
        console.log(error)
      }
    },
    async buyVip (item) {
      const item2 = {
        id: 9,
        title: 'كۇندىك مۇشە',
        limited_time: 1,
        price: 0.01,
        status: '1',
        created_at: '2021-03-22 14:30:04',
        updated_at: '2021-04-01 00:46:19',
        deleted_at: null
      }

      const { limited_time: limitedDay, price, id: memberId, title } = item2
      const limitedTime = this._handleTime(limitedDay)

      try {
        await this.$dialog.confirm({
          title,
          message: `ۋاقىتى ${limitedDay} كۇن، تولەم اقىسى ${price} يۋان`,
          confirmButtonText: 'تۇراقتاندىرۋ',
          cancelButtonText: 'قالدىرۋ'
        })

        this.payParams = {
          memberName: title,
          limitedTime,
          memberId,
          price: price * 100
        }
        this.limitedDay = limitedDay
        this._pay()
      } catch (error) {
        console.log(error)
      }
    },
    /**
     * 会员日期格式化
     */
    _handleTime (memberDay) {
      return addDate(today(), memberDay)
    },

    async _pay () {
      const _this = this
      const openId = _this.$cookies.get('openId')
      if (!openId) {
        location.href = `${wechatRedirect}?url=${encodeURIComponent(
          location.href
        )}&scope=snsapi_userinfo`
      }

      _this.payParams.openId = openId

      const { code, data } = await createVipMember(_this.payParams)
      if (code === 200) {
        wx.chooseWXPay({
          timestamp: data.timeStamp,
          nonceStr: data.nonceStr,
          package: data.package,
          signType: data.signType,
          paySign: data.paySign,
          success: async (res) => {
            if (res.errMsg === 'chooseWXPay:ok') {
              orderPayStatus('success', {
                ..._this.payParams,
                orderNumber: data.order_number
              })

              setTimeout(() => {
                location.reload()
              }, 500)
            } else if (res.errMsg === 'chooseWXPay:fail') {
              _this._userCancel()
            }
          },
          cancel: function () {
            _this._userCancel()
          },
          fail: async (res) => {
            alert(res.message || 'اقشا تولەۋ ءساتسىز بولدى')
          }
        })
      }
    },

    // 用户取消付款提示
    async _userCancel () {
      try {
        await this.$dialog.confirm({
          title: 'ەسكەرتۋ',
          message:
            'ءسىز اقشا تولەۋدى كۇشىنەن قالدىردىڭىز، قايتالاي اقشا تولەۋدى باسىڭىز!',
          confirmButtonText: 'اقشا تولەۋ',
          cancelButtonText: 'قالدىرۋ'
        })
        this._pay()
      } catch (error) {
        console.log(error)
      }
    }
  }
}
</script>

<style lang="less" scoped>
.vip {
  .colon {
    display: inline-block;
    margin: 0 14px;
    color: #ee0a24;
  }
  .block {
    display: inline-block;
    width: 60px;
    height: 60px;
    line-height: 60px;
    color: #fff;
    font-size: 28px;
    text-align: center;
    background-color: #ee0a24;
  }

  .vip-desc {
    font-size: 28px;
    direction: rtl;
    text-align: justify;
    padding: 2px 20px 30px;
    background-color: #fff;
    margin: 20px;
  }
  .memberlist {
    display: flex;
    align-items: center;
    background-color: #fff;
    border-radius: 10px;
    margin: 20px;
    box-shadow: 0 8px 12px #ebedf0;
    direction: rtl;
    padding: 20px 40px 20px 20px;

    .van-button {
      margin-right: auto;
      height: 60px;
    }

    h4 {
      font-size: 32px;
      margin-bottom: 10px;
    }

    p {
      font-size: 26px;
      color: #999;
    }
  }

  .vip-tips {
    direction: rtl;
    font-size: 26px;
    color: #ee0a24;
    padding: 30px;
  }
}
</style>
